import request from '@/services/request'
import apis from '@/services/apis'

export const getInvoices = (pageSize, pageNumber, query) => {
  let filter = ''
  if (query) {
    filter = `&filter=${query}`
  }
  return request.get({
    url: `${apis.invoices}?pageSize=${pageSize}&pageNumber=${pageNumber}${filter}`
  })
}

export const getInvoiceById = (id) => {
  return request.get({
    url: `${apis.invoices}/${id}`
  })
}

export const createInvoice = (invoice) => {
  return request.post({
    url: `${apis.invoices}`,
    data: invoice
  })
}

export const updateInvoice = (invoice) => {
  return request.put({
    url: `${apis.invoices}`,
    data: invoice
  })
}

export const deleteInvoice = (id) => {
  return request.delete(`${apis.invoices}/${id}`)
}

export const cancelInvoice = (id) => {
  return request.post({
    url: `${apis.invoices}/${id}/cancel`
  })
}

export const sendInvoice = (id, data) => {
  return request.post({
    url: `${apis.invoices}/${id}/send`,
    data: data
  })
}

export const addRemoveInvoice = (id, data) => {
  return request.post({
    url: `${apis.invoices}/${id}/CreditDebitNotes/addRemove`,
    data: data
  })
}

export const verifyInvoiceCA = (data) => {
  return request.post({
    url: apis.verifyInvoiceCA,
    data: data
  })
}
